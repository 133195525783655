//
//
//
//
//
//
//
//
//

// @ is an alias to /src
import DataApi from 'fm-data-api'

export default {
  name: 'Bareme',
  data: function () {
    return {
      focus: []
    }
  },
  methods: {
    async getFocus () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      this.dataApi = new DataApi(options)
      await this.dataApi.findRecords('W_Focus', { 'foc_RBQ__Rubrique::d_nomRubrique': 'Barème', 'foc_rbq_SOC__Societe::d_nomSociete': 'Tolus Immo' }, '', '', '')
        .then((focusList) => {
          focusList.forEach(function (focus) {
            this.$set(this.focus, focus.fieldData.d_type, {
              titre: focus.fieldData.d_titre,
              texte: focus.fieldData.d_texte,
              theme: focus.fieldData.d_theme,
              image: focus.fieldData['foc_PHOP__PhotoPrincipale::d_image']
            })
          }, this)
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.getFocus())
  }
}
